import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

export const useTilesStore = defineStore('tiles', () => {
	const tiles = ref({});

	const addTile = (h3Index, tileData) => {
		tiles.value[h3Index] = tileData;
	};

	const hasTile = (h3Index) => {
		return !!tiles.value[h3Index];
	};

	const fetchTile = async (h3Index) => {
		try {
			if (hasTile(h3Index)) { return tiles.value[h3Index]; }

			const tileDocRef = doc(db, 'tiles', h3Index);
			const tileDoc = await getDoc(tileDocRef);
			if (tileDoc.exists()) {
				const tileData = tileDoc.data();
				addTile(h3Index, tileData);
				return tileData;
			} else {
			}
		} catch (error) {
			console.error('Error fetching tile:', error);
		}
		return null;
	};

	const fetchTiles = async (h3Indexes) => {
		const tileFetchPromises = h3Indexes.map(async (h3Index) => {
			return await fetchTile(h3Index);
		});
		return await Promise.all(tileFetchPromises);
	};

	const getTiles = () => {
		return tiles.value;
	};

	return {
		tiles,
		addTile,
		hasTile,
		getTiles,
		fetchTile,
		fetchTiles,
	};
});