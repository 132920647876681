<script setup>
import { ref, onMounted } from 'vue';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';

const trees = ref([]);

onMounted(async () => {
    const querySnapshot = await getDocs(collection(db, 'trees'));
    querySnapshot.forEach((doc) => {
        trees.value.push({ id: doc.id, data: doc.data() });
    });
});
</script>

<template>
    <div>
        <h2>Tree List</h2>
        <table>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Age (years)</th>
                    <th>Height (m)</th>
                    <th>Health</th>
                    <!-- Additional columns -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="tree in trees" :key="tree.id">
                    <td>{{ tree.data.type }}</td>
                    <td>{{ tree.data.age }}</td>
                    <td>{{ tree.data.height }}</td>
                    <td>{{ tree.data.health }}</td>
                    <!-- Additional data -->
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
/* Table styles */
table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
}

thead {
    background-color: #4caf50;
    color: white;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

h2 {
    text-align: center;
    margin-bottom: 1em;
}
</style>
