import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

export const useTreesStore = defineStore('trees', () => {
	const trees = ref({});

	const addTree = (treeId, treeData) => {
		trees.value[treeId] = treeData;
	};

	const hasTree = (treeId) => {
		return !!trees.value[treeId];
	};

	const fetchTree = async (treeId) => {
		try {
			if (hasTree(treeId)) { return trees.value[treeId]; }

			const treeDocRef = doc(db, 'trees', treeId);
			const treeDoc = await getDoc(treeDocRef);
			if (treeDoc.exists()) {
				const treeData = treeDoc.data();
				addTree(treeId, treeData);
				return treeData;
			}
		} catch (error) {
			console.error('Error fetching tree:', error);
		}
		return null;
	};

	const fetchTrees = async (treeRefs) => {
		if (!treeRefs) { return []; }
		const treeFetchPromises = treeRefs.map(async (treeRef) => {
			return await fetchTree(treeRef.id);
		});
		return await Promise.all(treeFetchPromises);
	}

	const fetchTreesFromTiles = async (tiles) => {
		const treeFetchPromises = tiles.map(async (tile) => {
			if (tile) {
				const treeRefs = tile.trees;
				const trees = await fetchTrees(treeRefs);
				return trees;
			}
		});
		const treesArrays = await Promise.all(treeFetchPromises);
		const flatTrees = treesArrays.flat();
		return flatTrees;
	}

	const getTrees = () => {
		return trees.value;
	};

	return {
		trees,
		addTree,
		hasTree,
		fetchTree,
		fetchTrees,
		fetchTreesFromTiles,
		getTrees,
	};
});