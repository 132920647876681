import { createRouter, createWebHistory } from 'vue-router';
import MapView from '../components/MapView.vue';
import TreeForm from '../components/NewTreeForm.vue';
import TreeList from '../components/TreeList.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: MapView,
    },
    {
        path: '/add-tree',
        name: 'AddTree',
        component: TreeForm,
    },
    {
        path: '/tree-list',
        name: 'TreeList',
        component: TreeList,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
