// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyDgmXEg0nkUVMkNFwSTQVJK2-9mlr9bYDs',
    authDomain: 'communitree-14447.firebaseapp.com',
    projectId: 'communitree-14447',
    storageBucket: 'communitree-14447.appspot.com',
    messagingSenderId: '71551958390',
    appId: '1:71551958390:web:2f201edd711cd7e705ce57',
    measurementId: 'G-1WY2GGZ9RX',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
