<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue';
import { geohashForLocation } from 'geofire-common';
import { db } from '../../firebase';
import { collection, addDoc, updateDoc, doc, setDoc, getDoc, FieldValue } from 'firebase/firestore';
import { latLngToCell } from 'h3-js';

import treeData from '../assets/treeData.json';

// Define props and emits
const props = defineProps({
  coordinates: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['tree-added', 'update:coordinates']);

// Initialize treeTypes and subtypes
const treeTypes = ref(Object.keys(treeData).map((type) => ({ name: type })));
const treeSubtypes = ref([]);
const filteredTreeTypes = ref(treeTypes.value);

// Initialize form data
const form = ref({
  coordinates: { lat: props.coordinates.lat, lng: props.coordinates.lng },
  type: null,
  subtype: null,
});

const isLoading = ref(false);

// Method to add a tree
const addTree = async () => {
  const latitude = parseFloat(form.value.coordinates.lat);
  const longitude = parseFloat(form.value.coordinates.lng);

  const geohash = geohashForLocation([latitude, longitude]);
  const h3Index = latLngToCell(latitude, longitude, 9);

  const treeDataToSave = {
    geohash: geohash,
    lat: latitude,
    lng: longitude,
    type: form.value.type ? form.value.type.name : '',
    subtype: form.value.subtype ? form.value.subtype.name : '',
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  try {
    isLoading.value = true;
    const treeDocRef = await addDoc(collection(db, 'trees'), treeDataToSave);
    const tileDocRef = doc(db, 'tiles', h3Index);
    const tileDocSnapshot = await getDoc(tileDocRef);

    if (tileDocSnapshot.exists()) {
      const existingTrees = tileDocSnapshot.data().trees || [];
      existingTrees.push(doc(db, `trees/${treeDocRef.id}`));

      await setDoc(
        tileDocRef,
        {
          trees: existingTrees,
          updatedAt: new Date(),
        },
        { merge: true }
      );
    } else {
      await setDoc(tileDocRef, {
        trees: [doc(db, `trees/${treeDocRef.id}`)],
        updatedAt: new Date(),
      });
    }

    alert('Tree added successfully!');
    emit('tree-added');

    form.value = {
      coordinates: { lat: props.coordinates.lat, lng: props.coordinates.lng },
      type: null,
      subtype: null,
    };
  } catch (error) {
    console.error('Error adding tree:', error);
    alert('An error occurred while adding the tree.');
  } finally {
    isLoading.value = false;
  }
};

// Watch for type changes to update subtypes
watch(
  () => form.value.type,
  (newType) => {
    if (newType) {
      treeSubtypes.value = (treeData[newType.name] || []).map((subtype) => ({ name: subtype }));
      form.value.subtype = null;
    } else {
      treeSubtypes.value = [];
      form.value.subtype = null;
    }
  }
);

// Watch for coordinates changes
watch(
  () => props.coordinates,
  (newCoords) => {
    if (newCoords && typeof newCoords.lat === 'number' && typeof newCoords.lng === 'number') {
      form.value.coordinates = { lat: newCoords.lat, lng: newCoords.lng };
    } else {
      console.error('Invalid coordinates:', newCoords);
    }
  }
);

// Handle type selection
const selectType = (type) => {
  if (!type) {
    console.error('No type selected');
    return;
  }
  form.value.type = type;
  console.log('Selected type:', form.value.type);  // Debugging
};

// Handle subtype selection
const selectSubtype = (subtype) => {
  form.value.subtype = subtype;
};

// Clear type selection
const clearType = () => {
  form.value.type = null;
  form.value.subtype = null;
  treeSubtypes.value = [];
  clearSubtype();
  filteredTreeTypes.value = treeTypes.value;
};

// Clear subtype selection
const clearSubtype = () => {
  form.value.subtype = null;
};

// Filter types based on search input
const filterTypes = (searchTerm) => {
  if (searchTerm) {
    filteredTreeTypes.value = treeTypes.value.filter((type) =>
      type.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  } else {
    filteredTreeTypes.value = treeTypes.value;
  }
};
</script>

<template>
  <div>
    <h2>Add a New Tree</h2>
    <form @submit.prevent="addTree">

      <!-- Coordinates -->
      <div style="display: none">
        <input
          v-model.number="form.coordinates.lat"
          placeholder="Latitude"
          required
        />
        <input
          v-model.number="form.coordinates.lng"
          placeholder="Longitude"
          required
        />
      </div>

      <!-- Genus -->
      <div id="genus-container">
        <label>Genus</label>
        <input
          v-if="!form.type"
          placeholder="Filter genus..."
          @input="filterTypes($event.target.value)"
        />
        <div class="chip-container">
          <span
            v-for="type in filteredTreeTypes"
            :key="type.name"
            class="chip"
            :class="{ selected: form.type && form.type.name === type.name }"
            :style="{ display: form.type && form.type.name !== type.name ? 'none' : 'inline-block' }"
            @click="selectType(type)"
          >
            {{ type.name }}
          </span>
        </div>
      </div>

      <!-- Species -->
      <div v-if="treeSubtypes.length > 0 && form.type">
        <label>Species</label>
        <div class="chip-container">
          <span
            v-for="subtype in treeSubtypes"
            :key="subtype.name"
            class="chip"
            :class="{ selected: form.subtype && form.subtype.name === subtype.name }"
            @click="selectSubtype(subtype)"
          >
            {{ subtype.name }}
          </span>
        </div>
      </div>

      <!-- Form Buttons -->
      <div id="buttons-container">
        <button
          id="reset-button"
          @click="clearType"
          type="button"
          :disabled="isLoading || !form.type"
        >
          Reset
        </button>
        <button
          id="submit-button"
          type="submit"
          :disabled="isLoading || !form.type"
        >
          {{ isLoading ? 'Adding...' : `Add ${form.subtype ? form.subtype.name : ''} ${form.type ? form.type.name : 'Tree'}` }}
        </button>
      </div>

    </form>
  </div>
</template>

<style scoped>
form {
  text-align: left;
  padding: 5px;
  box-sizing: border-box;
}

form div {
  margin-bottom: 10px;
}

label {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

input {
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#genus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#buttons-container button {
  padding: 0.75em 1.5em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

#submit-button {
  background-color: #2196f3;
  color: white;
}

#submit-button:hover {
  background-color: #0b7dda;
}

#submit-button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

#reset-button {
  background-color: red;
  color: white;
}

#reset-button:disabled {
  background-color: lightcoral;
  cursor: not-allowed;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  max-height: 250px;
  overflow-y: auto;
  margin-top: 2px;
  padding: 5px;
}

.chip {
  padding: 0.5rem 1rem;
  background-color: #f1f1f1;
  border-radius: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.chip.selected {
  background-color: green;
  color: white;
}
</style>