<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { LMap, LTileLayer, LMarker, LPopup, LCircle } from '@vue-leaflet/vue-leaflet';
import { Icon } from 'leaflet';
import QuickTreeForm from './NewTreeForm.vue';
import { polygonToCells } from 'h3-js';
import { useTilesStore } from '../stores/tilesStore';
import { useTreesStore } from '../stores/treesStore';

const userPosition = ref({ lat: 0, lng: 0 });
const mapCenter = ref({ lat: 0, lng: 0 });
const tilesStore = useTilesStore();
const treesStore = useTreesStore();
const trees = ref([]);
const mapRef = ref(null);
const currentZoom = ref(19);
const isFormVisible = ref(false);
const isSelectingLocation = ref(false);
const isCenteredOnUser = ref(true);
const isProgrammaticMove = ref(false);
const newTreeCoordinates = ref({ lat: 0, lng: 0 });
let watchID = null;
const accuracy = ref(0);
const activeLayer = ref('satellite');

const createIcon = (iconUrl) =>
  new Icon({
    iconUrl,
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });
const greenIcon = createIcon(
  'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png'
);
const redIcon = createIcon(
  'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png'
);
const userIcon = createIcon(
  'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png'
);

/*
 * ===Map Interaction===
 */
const centerOnUser = () => {
  isProgrammaticMove.value = true;
  mapCenter.value = { ...userPosition.value };
  currentZoom.value = 19;
};

const toggleCenterOnUser = () => {
  isCenteredOnUser.value = !isCenteredOnUser.value;
  if (isCenteredOnUser.value) {
    centerOnUser();
  }
};

const toggleLayer = () => {
  activeLayer.value =
    activeLayer.value === 'satellite' ? 'plain' : 'satellite';
};

/*
 * ===Map Event Handlers===
 */
const onMoveStart = () => {
  if (!isProgrammaticMove.value) {
    isCenteredOnUser.value = false;
  }
};

const onMove = (e) => {
  if (isSelectingLocation.value) {
    const center = e.target.getCenter();
    newTreeCoordinates.value = { lat: center.lat, lng: center.lng };
  }
};

const onMoveEnd = async () => {
  isProgrammaticMove.value = false;
};

const onZoomEnd = async (e) => {
  currentZoom.value = e.target.getZoom();
};

const onUpdateBounds = async (bounds) => {
  let { _northEast, _southWest } = bounds;
  // offset the bounds to fetch trees in a larger area
  // 0.001562 is ~half the width of a resultion 9 tile
  // This should make sure we fetch all tiles in the visible area
  _northEast.lat += 0.001562;
  _northEast.lng += 0.001562;
  const polygon = [
    [_northEast.lat, _northEast.lng],
    [_northEast.lat, _southWest.lng],
    [_southWest.lat, _southWest.lng],
    [_southWest.lat, _northEast.lng],
    [_northEast.lat, _northEast.lng],
  ];

  // if zoom level is less than 16, don't fetch trees
  if (currentZoom.value < 16) { return; }
  await updateTreesInBounds(polygon);
};

/*
 * ===Tree Form Event Handlers===
 */
const onTreeAdded = async () => {
  isFormVisible.value = false;
};

/*
 * ===Location Selection Handlers===
 */
const startLocationSelection = () => {
  isSelectingLocation.value = true;
  newTreeCoordinates.value = { ...mapCenter.value };
};

const cancelLocationSelection = () => {
  isSelectingLocation.value = false;
  newTreeCoordinates.value = { lat: 0, lng: 0 };
};

const confirmLocationSelection = () => {
  isSelectingLocation.value = false;
  toggleForm();
};

const toggleForm = () => {
  isFormVisible.value = !isFormVisible.value;
  if (!isFormVisible.value) {
    newTreeCoordinates.value = { lat: 0, lng: 0 };
  }
};

/*
 * ===Database Interaction===
 */
const updateTreesInBounds = async (polygon) => {
  const h3Indices = polygonToCells(polygon, 9);
  const tiles = await tilesStore.fetchTiles(h3Indices);
  let fetchedTrees = await treesStore.fetchTreesFromTiles(tiles);
  // remove undefined items from the array
  fetchedTrees = fetchedTrees.filter((tree) => tree);
  trees.value = fetchedTrees;
};

/*
 * ===Lifecycle Hooks===
 */
onMounted(async () => {
  watchID = navigator.geolocation.watchPosition(
    (pos) => {
      userPosition.value = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      };
      accuracy.value = pos.coords.accuracy;

      if (isCenteredOnUser.value) { centerOnUser(); }
    },
    (error) => {
      console.error(error);
      alert('Unable to retrieve your location.');
    },
    {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }
  );
});

onBeforeUnmount(() => {
  if (watchID) {
    navigator.geolocation.clearWatch(watchID);
    watchID = null;
  }
});

watch(trees, (newTrees) => {
  console.log("Trees updated:", newTrees);
});
</script>

<template>
  <div id="map-container">
    <!-- Position Tracking Button -->
    <button
      id="track-location-button"
      @click="toggleCenterOnUser()"
      :style="{
        backgroundColor: isCenteredOnUser ? '#4caf50' : '#f44336',
      }"
    >
      <img
        :src="
          'https://img.icons8.com/?size=100&id=' +
          (isCenteredOnUser ? '3396' : '41444') +
          '&format=png&color=ffffff'
        "
        alt="Start Tracking"
        style="width: 24px; height: 24px;"
      />
    </button>

    <!-- Refresh Trees Button -->
    <!-- <button id="refresh-trees-button" @click="updateTrees">
      <img
        src="https://img.icons8.com/?size=100&id=59872&format=png&color=ffffff"
        alt="Refresh Trees"
        style="width: 24px; height: 24px;"
      />
    </button> -->

    <!-- Layer Toggle Button -->
    <button id="layer-toggle-button" @click="toggleLayer">
      <img
        src="https://img.icons8.com/?size=100&id=113299&format=png&color=ffffff"
        alt="Toggle Layers"
        style="width: 24px; height: 24px;"
      />
    </button>

    <!-- Add Tree Button -->
    <button
      v-if="!isSelectingLocation"
      id="add-tree-button"
      @click="startLocationSelection"
    >
      +
    </button>

    <div v-if="isSelectingLocation" class="location-buttons">
      <button id="confirm-location-button" @click="confirmLocationSelection">
        ✓
      </button>
      <button id="cancel-location-button" @click="cancelLocationSelection">
        ✕
      </button>
    </div>

    <!-- TreeForm Side Panel -->
    <div v-if="isFormVisible" class="form-panel">
      <button class="close-button" @click="toggleForm">&times;</button>
      <QuickTreeForm
        :coordinates="newTreeCoordinates"
        @tree-added="onTreeAdded"
      />
    </div>

    <!-- Map -->
    <l-map
      ref="mapRef"
      :zoom="currentZoom"
      v-model:center="mapCenter"
      style="height: 100%; width: 100%"
      @move="onMove"
      @moveStart="onMoveStart"
      @moveend="onMoveEnd"
      @zoomend="onZoomEnd"
      @update:bounds="onUpdateBounds"
    >
      <l-tile-layer
        maxZoom="19"
        :url="
          activeLayer === 'satellite'
            ? 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        "
      />

      <!-- User's current position -->
      <l-marker :lat-lng="userPosition" :icon="userIcon" class="user-marker">
        <l-popup>Your current location</l-popup>
      </l-marker>

      <!-- Existingn Trees Markers -->
      <l-circle
        v-for="tree in trees"
        class="tree-marker"
        :key="tree.geohash"
        :lat-lng="{ lat: tree.lat, lng: tree.lng }"
        radius="5"
        color="green"
        fillColor="green"
        fillOpacity="0.25"
      >
        <!-- Marker Popup -->
        <l-popup>
          <div>
        {{ tree.subtype }} {{ tree.type }}
          </div>
        </l-popup>
      </l-circle>

      <!-- New Tree Marker -->
      <l-marker
        v-if="isSelectingLocation"
        class="new-tree-marker"
        :lat-lng="newTreeCoordinates"
        :icon="redIcon"
      ></l-marker>
    </l-map>

    <!-- Location Accuracy -->
    <div class="position-display">
      <div v-if="!isSelectingLocation">
        Accuracy: {{ accuracy.toFixed(1) }}m
      </div>
      <div>
        Lat: {{
          (isSelectingLocation
            ? newTreeCoordinates.lat
            : userPosition.lat
          ).toFixed(5)
        }}
      </div>
      <div>
        Lng: {{
          (isSelectingLocation
            ? newTreeCoordinates.lng
            : userPosition.lng
          ).toFixed(5)
        }}
      </div>
      <div>
        Zoom: {{ currentZoom }}
      </div>
    </div>
  </div>
</template>

<style scoped>
#map-container {
  position: relative;
  height: 93.2vh;
}

#layer-toggle {
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
}

#layer-toggle-button {
  position: absolute;
  z-index: 1000;
  top: 60px;
  right: 10px;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.position-display {
  position: absolute;
  z-index: 1000;
  top: 13px;
  left: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
  color: #333;
}

#track-location-button,
#add-tree-button {
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

#add-tree-button {
  top: 555px;
}

#track-location-button:hover,
#add-tree-button:hover,
#layer-toggle-button:hover {
  background-color: #45a049;
}

#refresh-trees-button {
  position: absolute;
  z-index: 1000;
  top: 110px;
  right: 10px;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

#refresh-trees-button:hover {
  background-color: #45a049;
}

.location-buttons {
  position: absolute;
  z-index: 1000;
  top: 555px;
  right: 10px;
  display: flex;
  gap: 10px;
}

#confirm-location-button,
#cancel-location-button {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

#confirm-location-button {
  background-color: #4caf50;
  color: white;
}

#cancel-location-button {
  background-color: #f44336;
  color: white;
}

#confirm-location-button:hover {
  background-color: #45a049;
}

#cancel-location-button:hover {
  background-color: #e53935;
}

.tree-marker {
  z-index: 1000;
}

/* Form Panel Styles */
.form-panel {
  position: absolute;
  top: 60px;
  right: 10px;
  width: 300px;
  max-height: 80vh;
  background-color: white;
  border-radius: 8px;
  z-index: 1500;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .form-panel {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}

.close-button:hover {
  color: #000;
}
</style>